import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import { connect } from 'react-redux'
import concat from 'lodash/concat'
import get from 'lodash/get'
import kebabCase from 'lodash/kebabCase'
import without from 'lodash/without'

import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'

import InfoIcon from '@mui/icons-material/InfoOutlined'

import { DEFAULT_APPROACH_SETTINGS } from 'shared/constants/report'

import { updateSelectedTemplates as updateSelectedTemplatesAction } from './redux/actions'

const ERROR_MESSAGE = 'There is an error occurred. Try to reload page a bit later'
const TOOLTIP_TEXT = 'All sections selected below will appear in your exported report.'
const TITLE_TEXT = 'Export Report'
const STATE = {
  NOT_SELECTED: 'Select All',
  SELECTED: 'Deselect All',
}

const Title = ({ title, tooltip }) => (
  <Stack alignItems="center" direction="row" spacing={1} width={1}>
    <Typography variant="h6">{title}</Typography>
    <Tooltip placement="top" title={tooltip}>
      <InfoIcon />
    </Tooltip>
  </Stack>
)

const SkeletonList = () => {
  const SkeletonLine = React.memo(() => (
    <Stack direction="row" spacing={1}>
      <Skeleton height={16} width={16} variant="circular" />
      <Skeleton height={16} width="100%" />
    </Stack>
  ))
  return (
    <Stack minHeight={128} justifyContent="space-evenly" data-qa="loadingList">
      <SkeletonLine />
      <SkeletonLine />
      <SkeletonLine />
    </Stack>
  )
}

const ReportSelectorItem = ({ checked, label, value, handleCheck }) => (
  <ListItem disablePadding divider key={value}>
    <ListItemButton dense onClick={() => handleCheck(value, checked)}>
      <ListItemIcon>
        <Checkbox checked={checked} color="primary" data-qa={label} value={checked} />
      </ListItemIcon>
      <ListItemText primary={label} />
    </ListItemButton>
  </ListItem>
)

const ErrorMessage = () => <Alert severity="error">{ERROR_MESSAGE}</Alert>

const ReportSelectorTable = ({ templates, selectedTemplates, onChange }) => {
  const addTemplate = React.useCallback(
    template => onChange(concat(selectedTemplates, template)),
    [onChange, selectedTemplates]
  )
  const removeTemplate = React.useCallback(
    template => onChange(without(selectedTemplates, template)),
    [onChange, selectedTemplates]
  )
  const handleCheck = React.useCallback(
    (value, checked) => (checked ? removeTemplate(value) : addTemplate(value)),
    [addTemplate, removeTemplate]
  )

  return (
    <List sx={{ width: '100%', maxHeight: 256, overflowY: 'auto' }}>
      {templates.map(({ label, value }) => {
        const checked = !!selectedTemplates.find(selectedSection => selectedSection === value)
        return <ReportSelectorItem key={value} {...{ checked, label, value, handleCheck }} />
      })}
    </List>
  )
}

const removeTemplateFromList = (templates, label) => {
  return templates.filter(template => template.label !== label)
}

const ReportStructureSelector = ({
  exportTemplates,
  isLoading,
  selectedTemplates,
  updateSelectedTemplates,
  approaches,
}) => {
  const isAllSelected = selectedTemplates.length === exportTemplates.length
  const buttonState = isAllSelected ? STATE.SELECTED : STATE.NOT_SELECTED

  const [filteredExportTemplates, setFilteredExportTemplates] = useState([])

  useEffect(() => {
    let updatedTemplates = exportTemplates
    if (!approaches.income) {
      // TODO: 10437
      updatedTemplates = removeTemplateFromList(updatedTemplates, 'Income Capitalization Approach')
    }
    if (!approaches.cost) {
      updatedTemplates = removeTemplateFromList(updatedTemplates, 'Cost Approach')
    }
    if (!approaches.improvedSales) {
      updatedTemplates = removeTemplateFromList(updatedTemplates, 'Sales Comparison Approach')
    }
    if (!approaches.land) {
      updatedTemplates = removeTemplateFromList(updatedTemplates, 'Land Value Comparison Approach')
    }

    setFilteredExportTemplates(updatedTemplates)
  }, [approaches.cost, approaches.improvedSales, approaches.land, approaches.income, exportTemplates])

  const onChange = React.useCallback(
    templates => {
      updateSelectedTemplates({ selectedTemplates: templates })
    },
    [updateSelectedTemplates]
  )

  const selectAllSections = React.useCallback(() => {
    if (buttonState === STATE.SELECTED) {
      onChange([])
    } else {
      const templateNames = filteredExportTemplates.map(({ value }) => value)
      onChange(templateNames)
    }
  }, [buttonState, onChange, filteredExportTemplates])

  const isEmpty = filteredExportTemplates?.length === 0
  const noContent = isEmpty && !isLoading
  const isReady = !isLoading && !isEmpty

  return (
    <Stack>
      <Stack direction="row">
        <Title title={TITLE_TEXT} tooltip={TOOLTIP_TEXT} />
        <Button
          data-qa={`${kebabCase(buttonState)}-all-btn`}
          onClick={selectAllSections}
          sx={{ ml: 'auto', whiteSpace: 'nowrap' }}
        >
          {buttonState}
        </Button>
      </Stack>
      <Divider />
      {isLoading && <SkeletonList />}
      {noContent && <ErrorMessage />}
      {isReady && (
        <ReportSelectorTable
          templates={filteredExportTemplates}
          selectedTemplates={selectedTemplates}
          onChange={onChange}
        />
      )}
    </Stack>
  )
}

ReportStructureSelector.propTypes = {
  exportTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ),
  selectedTemplates: PropTypes.arrayOf(PropTypes.string),
  approaches: PropTypes.shape({
    income: PropTypes.bool,
    cost: PropTypes.bool,
    land: PropTypes.bool,
    improvedSales: PropTypes.bool,
  }),
}

ReportStructureSelector.defaultProps = {
  exportTemplates: [],
  selectedTemplates: [],
  approaches: DEFAULT_APPROACH_SETTINGS,
}

export default connect(
  state => {
    const exportTemplates = get(state, 'export.exportTemplates')
    const isLoading = get(state, 'export.isLoadingTemplates')
    const selectedTemplates = get(state, 'export.selectedTemplates')
    const approaches = get(state, 'report.reportSettings.approaches')

    return {
      exportTemplates,
      isLoading,
      selectedTemplates,
      approaches,
    }
  },
  {
    updateSelectedTemplates: updateSelectedTemplatesAction,
  }
)(ReportStructureSelector)
