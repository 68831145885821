import { GeneratedText, gtParagraph } from '../../../../narrative/GeneratedText'

const generate = () => {
  const noPenalty =
    'To the best of our knowledge, the subject is required to comply with Local Law 97. According to the NYC Accelerator, the subject’s projected LL 97 annual penalty for 2024-2029 is expected to be $0 per year. Their forecasted penalty between 2030 and 2034 is also $0 per year. Given that there are no projected penalties, we assume that the building has made various steps given its recent renovation to comply with this law but consider future risk of tighter energy standards in the future.'

  const gt = new GeneratedText()

  gt.addPart(gtParagraph(noPenalty))

  return gt.getParts()
}

export default { generate }
