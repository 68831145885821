import React from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import createDecorator from 'final-form-calculate'

import { Grid } from '@mui/material'

import { AMENITY_OPTIONS } from 'shared/constants/buildingComparables'

import { BEDROOM_COUNT_LIST } from '../../../../../../constants'
import { SwitchListField as SwitchList } from '../../../../../../../shared/components/Switch/SwitchListField'

import FiltersPanel from '../FiltersPanel'

import {
  searchProperties,
  updateBuildingCompsFilters,
} from '../../../../../../../etc/buildingComparables/redux/actions'
import { selectPropertySearchResults } from '../../redux/selectors'

const BEDROOM_COUNT_FIELD_NAME = 'selectedPropertySearchBedroomCounts'
const AMENITIES_FIELD_NAME = 'selectedPropertySearchAmenities'

const BEDROOM_COUNT_LIST_WITH_STRING_VALUES = BEDROOM_COUNT_LIST.map(bedroomCount => ({
  ...bedroomCount,
  value: String(bedroomCount.value),
}))

export const propertySearchFilterPanelDecorator = createDecorator({
  field: [BEDROOM_COUNT_FIELD_NAME, AMENITIES_FIELD_NAME],
  updates: (value, fieldName, allValues, prevValues) => {
    const { filterHistory = [] } = allValues
    const oldValue = prevValues[fieldName] || []

    if (!allValues.undoingFilter) {
      filterHistory.push({ value: oldValue, fieldName })
    }
    return { filterHistory, undoingFilter: false }
  },
})

class PropertySearchFilterPanel extends React.PureComponent {
  onCancel = () => {
    const { form } = this.props
    const { filterHistory = [] } = form.values
    if (!filterHistory.length) {
      return
    }
    const { fieldName, value } = filterHistory.pop()
    form.batch(() => {
      form.change('filterHistory', filterHistory)
      form.change(fieldName, value)
      form.change('undoingFilter', true)
    })
  }

  onSearch = () => {
    const { form, updateFilters, onSearch } = this.props
    const { selectedPropertySearchBedroomCounts = [], selectedPropertySearchAmenities = [] } = form.values

    updateFilters({
      amenities: selectedPropertySearchAmenities,
      bedroomCounts: selectedPropertySearchBedroomCounts,
    })
    onSearch()
  }

  render() {
    const { searchResultsCount } = this.props
    return (
      <FiltersPanel onCancel={this.onCancel} onSearch={this.onSearch} searchResultsCount={searchResultsCount}>
        <Grid container spacing={5} sx={{ width: 1300, marginBottom: 3 }}>
          <Grid item xs={3}>
            <SwitchList
              maxRowWidth={150}
              label="Unit Types"
              name={BEDROOM_COUNT_FIELD_NAME}
              items={BEDROOM_COUNT_LIST_WITH_STRING_VALUES}
            />
          </Grid>
          <Grid item xs={9}>
            <SwitchList maxRowWidth={295} label="Amenities" name={AMENITIES_FIELD_NAME} items={AMENITY_OPTIONS} />
          </Grid>
        </Grid>
      </FiltersPanel>
    )
  }
}

PropertySearchFilterPanel.propTypes = {
  updateFilters: PropTypes.func.isRequired,
  onSearch: PropTypes.func.isRequired,
  searchResultsCount: PropTypes.number.isRequired,
  form: PropTypes.object.isRequired,
}

const mapStateToProps = state => {
  return {
    searchResultsCount: selectPropertySearchResults(state).length,
  }
}

export default connect(mapStateToProps, {
  onSearch: searchProperties,
  updateFilters: updateBuildingCompsFilters,
})(PropertySearchFilterPanel)
