import { GeneratedText, gtParagraph, gtText } from '../../../../narrative/GeneratedText'

const generate = () => {
  const localLawIntro =
    "Local Law 97 was included in the Climate Mobilization Act, passed by the City Council in April 2019 as part of the Mayor's New York City Green New Deal. It is one of the most ambitious plans for reducing emissions in the nation. Local Law 97, which became effective on November 15, 2019, sets limits on the greenhouse gas emissions of covered buildings starting in 2024 to help New York City reach the goal of a 40 percent reduction in greenhouse gas emissions from buildings by the year 2030 and 80 percent reduction in citywide emissions by calendar year 2050."

  const bulletIntro = `NYC Local Law 97 applies to commercial buildings that are:\n`
  const firstBullet = `\t\u2022 Buildings that exceed 25,000 gross square feet.\n`
  const secondBullet = `\t\u2022 Two or more buildings on the same tax lot that together exceed 50,000 square feet.\n`
  const thirdBullet =
    '\t\u2022 Two or more buildings owned by a condo association that are governed by the same board of managers and that together exceed 50,000 square feet.'
  const gt = new GeneratedText()
  const bulletGt = new GeneratedText()

  bulletGt.addPart(gtParagraph(bulletIntro))
  bulletGt.addPart(gtText(firstBullet))
  bulletGt.addPart(gtText(secondBullet))
  bulletGt.addPart(gtText(thirdBullet))

  gt.addPart(gtParagraph(localLawIntro))
  gt.addParts(bulletGt.getParts())

  return gt.getParts()
}

export default { generate }
