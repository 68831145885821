import { GeneratedText, gtParagraph } from '../../../../narrative/GeneratedText'

const generate = () => {
  const paragraph1 =
    "Compliance requires building owners to create and present an emissions intensity report with a registered design professional's stamp of approval. Under these filings building owners must report carbon emissions from the previous year. Since the first year of reporting will cover 2024, the first report (certified by a registered design professional) must be filed with the Department by May 1, 2025. Detailing their annual greenhouse gas emissions and then by May 1 of every year after. Buildings that are required to undertake prescriptive measures (e.g. Affordable Housing) will also be required to submit a report of compliance with the Department by May 1, 2025."

  const paragraph2 =
    'Covered buildings must comply with building emission limits on and after January 1st, 2024. By May 1, 2025, and by May first of every year thereafter, the owner of a covered building shall file with the department a report, certified by a registered design professional, prepared in a form and manner and containing such information as specified in rules of the department, that for the previous calendar year such building is either:'
  const paragraph3 =
    '1. In compliance with the applicable building emissions limit established pursuant to section 28-320.3; or'
  const paragraph4 =
    '2. Not in compliance with such applicable building emissions limit, along with the amount by which such building exceeds such limit.'

  const paragraph5 =
    'Beginning in 2025, an owner of a covered building who has submitted a report pursuant to section 28-320.3.7 which indicates that such building has exceeded its annual building emissions limit will be liable for a civil penalty of not more than an amount equal to the difference between the building emissions limit for such year and the reported building emissions for such year, multiplied by $268.'

  const gt = new GeneratedText()

  gt.addPart(gtParagraph(paragraph1))
  gt.addPart(gtParagraph(paragraph2))
  gt.addPart(gtParagraph(paragraph3))
  gt.addPart(gtParagraph(paragraph4))
  gt.addPart(gtParagraph(paragraph5))

  return gt.getParts()
}

export default { generate }
