import IncomeCapApproach from './IncomeCapApproach'
import SWOTAnalysis from './SWOTAnalysis'
import LetterOfTransmittal from './LetterOfTransmittal'
import Certification from './Certification'
import AppraisalValuationProcess from './AppraisalValuationProcess'
import ReconciliationFinalValue from './ReconciliationFinalValue'
import RentStabilization from './RentStabilization'
import LocalLaw97 from './LocalLaw97'
import HighestAndBestUse from './HighestAndBestUse'
import SurveyOfCompetitiveRates from './SurveyOfCompetitiveRates'

export default {
  letterOfTransmittal: {
    name: 'Letter Of Transmittal',
    color: '#ae0808',
    formComponent: LetterOfTransmittal,
    permission: 'cms.view',
  },
  swotAnalysis: {
    name: 'SWOT Analysis',
    color: '#ae0808',
    formComponent: SWOTAnalysis,
    permission: 'cms.view',
  },
  appraisalValuationProcess: {
    name: 'Appraisal Valuation Process',
    color: '#ae0808',
    formComponent: AppraisalValuationProcess,
    permission: 'cms.view',
  },
  highestAndBestUse: {
    name: 'Highest and Best Use',
    color: '#ae0808',
    formComponent: HighestAndBestUse,
    permission: 'cms.view',
  },
  incomeCapApproach: {
    name: 'Income Capitalization Approach',
    color: '#ae0808',
    formComponent: IncomeCapApproach,
    permission: 'cms.view',
  },
  reconciliationFinalValue: {
    name: 'Reconciliation & Final Value',
    color: '#ae0808',
    formComponent: ReconciliationFinalValue,
    permission: 'cms.view',
  },
  rentStabilization: {
    name: 'Rent Stabilization',
    color: '#ae0808',
    formComponent: RentStabilization,
    permission: 'cms.view',
  },
  certification: {
    name: 'Certification',
    color: '#ae0808',
    formComponent: Certification,
    permission: 'cms.view',
  },
  surveyOfCompetitiveRates: {
    name: 'Survey of Competitive Rates',
    color: '#ae0808',
    formComponent: SurveyOfCompetitiveRates,
    permission: 'cms.view',
  },
  localLaw97: {
    name: 'Local Law 97',
    color: '#ae0808',
    formComponent: LocalLaw97,
    permission: 'cms.view',
  },
}
