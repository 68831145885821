import { get, invert, sum } from 'lodash'
import BoweryDate from '@bowery-valuation/bowery-date'

import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'

import { BOROUGH_COUNTY_LOOKUP, BOROUGH_LOOKUP } from '../../../../../constants/boroughs'
import { STATE_NAMES } from '../../../../../constants/states'
import { formatPercentageString } from '../../../../formatters/numberFormatters'

const SHORT_NAMES_LOOKUP = invert(STATE_NAMES)

export const mapDTO = formValues => {
  const {
    jurisdictionState,
    hasTransitionalAssessedValue,
    usesTransitionalAssessedValue,
    subjectInfo: {
      address,
      city,
      borough,
      block,
      lot,
      qualifier,
      propertyIdentifierType,
      propertyIdentifier,
      county,
      locationIdentifier,
    },
  } = formValues

  const taxYear = new BoweryDate(get(formValues, 'taxRate.effectiveDate')).year
  const taxRate = get(formValues, 'taxRate.value')
  const taxClass = get(formValues, 'taxClass.name')
  const taxRatePercentage = formatPercentageString(taxRate, 10)
  const stateShorthand = SHORT_NAMES_LOOKUP[jurisdictionState]
  const fullBoroughName = BOROUGH_LOOKUP[borough]
  const lowerAssessedValue = usesTransitionalAssessedValue ? 'Transitional Assessed Value' : 'Actual Assessed Value'

  return {
    jurisdictionState,
    stateShorthand,
    address,
    city,
    borough: fullBoroughName,
    county: county || BOROUGH_COUNTY_LOOKUP[borough],
    block,
    lot,
    qualifier,
    propertyIdentifierType,
    propertyIdentifier,
    taxClass,
    taxYear,
    taxRate: taxRatePercentage,
    hasTransitionalAssessedValue,
    usesTransitionalAssessedValue,
    lowerAssessedValue,
    locationIdentifier,
  }
}

export const mapDataModel = report => {
  const jurisdictionState = get(report, 'tax_info.jurisdictionState')
  const taxYear = new BoweryDate(get(report, 'tax_info.taxRate.effectiveDate')).year
  const taxRate = get(report, 'tax_info.taxRate.value')
  const taxClass = get(report, 'tax_info.taxClass.name')
  const taxRatePercentage = formatPercentageString(taxRate, 10)
  const stateShorthand = SHORT_NAMES_LOOKUP[jurisdictionState]
  const hasTransitionalAssessedValue = get(report, 'tax_info.has_transitional_av')
  const taxItems = get(report, 'tax_info.taxItems', [])
  const totalTransitional = sum(taxItems.map(taxItem => taxItem.transitionalValue))
  const totalActual = sum(taxItems.map(taxItem => taxItem.actualValue))
  const usesTransitionalAssessedValue = hasTransitionalAssessedValue && totalTransitional < totalActual

  const address = get(report, 'property_information.address')
  const city = get(report, 'property_information.city')
  const county = get(report, 'property_information.county')
  const borough = get(report, 'property_information.borough')
  const fullBoroughName = BOROUGH_LOOKUP[borough]
  const block = get(report, 'property_information.block')
  const lot = get(report, 'property_information.lot')
  const qualifier = get(report, 'property_information.qualifier')
  const propertyIdentifierType = get(report, 'property_information.propertyIdentifierType')
  const propertyIdentifier = get(report, 'property_information.propertyIdentifier')

  const state = get(report, 'property_information.state')
  const zip = get(report, 'property_information.zip')
  const locationIdentifier = getLocationIdentifier(state, zip, block, lot)
  const lowerAssessedValue = usesTransitionalAssessedValue ? 'Transitional Assessed Value' : 'Actual Assessed Value'

  return {
    jurisdictionState,
    stateShorthand,
    address,
    city,
    borough: fullBoroughName,
    county: county || BOROUGH_COUNTY_LOOKUP[borough],
    block,
    lot,
    qualifier,
    propertyIdentifierType,
    propertyIdentifier,
    taxClass,
    taxYear,
    taxRate: taxRatePercentage,
    hasTransitionalAssessedValue,
    usesTransitionalAssessedValue,
    lowerAssessedValue,
    locationIdentifier,
  }
}
