import { keyBy, uniq } from 'lodash'
import filterFP from 'lodash/fp/filter'
import flowFP from 'lodash/fp/flow'
import pickFP from 'lodash/fp/pick'

import { divide } from 'shared/utils/numberOperations'

import { VARIES_BY_RENT_TYPE, BasisTypes } from '../../../constants/report/incomeApproach/expenses'
import { EXPENSE_COMP_KEYS_ORDER } from '../../../../client/src/shared/utils/expenses/comps/constants'
import { UTILITIES_EXPENSE, REAL_ESTATE_TAXES } from '../../../constants/expenses'

export const isBothResponsibleForExpenses = value => {
  return value === VARIES_BY_RENT_TYPE
}

export const getNonSelectedBasisForecast = (
  selectedBasis,
  nonselectedBasis,
  concludedValue,
  totalRoomCount,
  squareFootage,
  numberOfResidentialUnits
) => {
  const basisMap = {
    [BasisTypes.PER_SF]: squareFootage,
    [BasisTypes.PER_UNIT]: numberOfResidentialUnits,
    [BasisTypes.PER_ROOM]: totalRoomCount,
    [BasisTypes.PER_YEAR]: 1,
  }
  const selectedBasisFactor = basisMap[selectedBasis]
  const fullValue = selectedBasisFactor ? concludedValue * selectedBasisFactor : concludedValue

  switch (nonselectedBasis) {
    case BasisTypes.PER_ROOM:
      return divide(fullValue, totalRoomCount)
    case BasisTypes.PER_UNIT:
      return divide(fullValue, numberOfResidentialUnits)
    case BasisTypes.PER_SF:
      return divide(fullValue, squareFootage)
    case BasisTypes.PER_YEAR:
      return fullValue
    default:
      return fullValue
  }
}

export const formatExpenseReimbursementUnits = (
  reimbursement,
  units = [],
  expenseCategories = null,
  { lowercaseExpenseType } = {}
) => {
  const expenseCategory = [{ id: REAL_ESTATE_TAXES.key, name: REAL_ESTATE_TAXES.name }, ...expenseCategories].find(
    category => category.id === reimbursement.expenseType
  )
  let formattedExpenseType = expenseCategory && expenseCategory.name
  if (formattedExpenseType && lowercaseExpenseType) {
    formattedExpenseType = formattedExpenseType.toLowerCase()
  }

  if (!reimbursement.isOneOfMultipleForThisExpenseType) {
    return formattedExpenseType
  }

  const unitNames = keyBy(units, 'id')

  const tenantNames = reimbursement.unitReimbursements.map(unit => unitNames[unit.unitId]?.tenantName)
  const uniqueNames = uniq(tenantNames).join(', ')
  return `${uniqueNames} ${formattedExpenseType}`
}

const getForecastExpenseData = expenses => {
  return expenses.map(forecast => {
    return { concludedValue: forecast.concludedValue, basis: forecast.basis }
  })
}

export const getForecastExpenseTotalsPerBasis = expenseForecast => {
  const forecastedDefaultExpenses = flowFP([
    pickFP([...EXPENSE_COMP_KEYS_ORDER, UTILITIES_EXPENSE.key]),
    filterFP(forecast => !forecast.skip && forecast?.includeInProForma),
    getForecastExpenseData,
  ])(expenseForecast)

  const forecastedCustomExpenses = getForecastExpenseData(expenseForecast.customExpenses)

  return forecastedDefaultExpenses.concat(forecastedCustomExpenses)
}
