import { get } from 'lodash'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'

import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'

export const mapDTO = ({ isNYCReport }: { isNYCReport: boolean }) => {
  return { isNYCReport }
}

export const mapDataModel = (report: any) => {
  const address = get(report, 'property_information') || {}

  const block = get(report, 'property_information.block')
  const lot = get(report, 'property_information.lot')
  const locationIdentifier = getLocationIdentifier(address.state, address.zip, block, lot)
  const isNYCReport = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  return { isNYCReport }
}
