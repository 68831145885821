import React from 'react'
import PropTypes from 'prop-types'

import { Box, Typography } from '@mui/material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'

import { COMMERCIAL_RENT_ROLL_PATH } from 'shared/constants/report/keysAndDataPaths'
import { OCCUPANCY_RATE_BASIS_OPTIONS } from 'shared/constants/incomeApproach/commercialIncome'
import { PropertyTypes } from 'shared/constants'

import { RadioButtonList } from 'client-shared/components'

import {
  Card,
  CardBody,
  CardContainer,
  CardHeader,
  Label,
  Stack,
  Tooltip,
} from '../../../../../../shared/components/_mui5'
import { ToggleButton } from '../../../../../../shared/components/Fields'
import { Information } from '../../../../../../shared/components/_mui5/Icons'

import wrapForm from '../../../../wrapForm'
import { SUBJECT_AS_COMP_OPTIONS } from '../../../../../constants'
import { RENT_TYPES_LIST } from '../../../../../constants/commercial'
import { Labels } from '../constants'
import { rentBasisDecorator } from '../decorators'

import RentRollTable from './RentRollTable'
import CurrentIncomeDiscussion from './CurrentIncomeDiscussion'

export const DATA_PATH = COMMERCIAL_RENT_ROLL_PATH

const SUBJECT_AS_COMP_TOOLTIP =
  "Upon draft submission in Salesforce this subject's expense data will be uploaded to the Bowery Database as a commercial rent comparable."

const CommercialRentRoll = ({ form, classes }) => {
  const propertyType = form.getState().values?.propertyType
  const isCommercialProperty = propertyType === PropertyTypes.COMMERCIAL
  return (
    <CardContainer>
      <Card>
        <CardHeader title="Commercial In-Place Rent Roll Table" sx={{ mr: 'auto' }}>
          <Stack direction="row" spacing={1} alignItems="center" sx={{ mx: 2 }}>
            <Label>Basis of Rent</Label>
            <Tooltip title="Select the unit of the provided rent roll." placement="top">
              <Information />
            </Tooltip>
          </Stack>
          <ToggleButton name="rentBasis" options={RENT_TYPES_LIST} variant="default" />
        </CardHeader>
        <CardBody fluid={true}>
          <Stack alignItems="center" direction="row" justifyContent="start" spacing={1} sx={{ mx: 2 }}>
            <Typography variant="subtitle1">Submit subject Rent Roll data to Bowery database?</Typography>
            <Tooltip title={SUBJECT_AS_COMP_TOOLTIP} placement="right">
              <InfoOutlinedIcon fontSize="small" />
            </Tooltip>
            <RadioButtonList horizontal name="subjectAsCommercialRentCompCondition" items={SUBJECT_AS_COMP_OPTIONS} />
          </Stack>
          {isCommercialProperty && (
            <Stack alignItems="center" direction="row" justifyContent="start" spacing={1} sx={{ mx: 2 }}>
              <Typography variant="subtitle1">Occupancy Rate Calculation Basis:</Typography>
              <Tooltip title="Occupancy Rate will be calculated based on units or square feet" placement="right">
                <InfoOutlinedIcon fontSize="small" />
              </Tooltip>
              <RadioButtonList horizontal name="occupancyRateBasis" items={OCCUPANCY_RATE_BASIS_OPTIONS} />
            </Stack>
          )}
          <RentRollTable form={form} />
        </CardBody>
      </Card>
      <Box p={2}>
        <CurrentIncomeDiscussion form={form} />
      </Box>
    </CardContainer>
  )
}

CommercialRentRoll.propTypes = {
  classes: PropTypes.object.isRequired,
}

const formOptions = {
  heading: Labels.COMMERCIAL_RENT_ROLL,
  subscription: {
    dirty: true,
    invalid: true,
    hasValidationErrors: true,
  },
  decorators: [rentBasisDecorator],
}

export default wrapForm(DATA_PATH, formOptions)(React.memo(CommercialRentRoll))
