import { BasisTypes } from '../../../../../../shared/constants/report/incomeApproach/expenses'
import {
  DEFAULT_EXPENSES_KEYS,
  ADDITIONAL_EXPENSES_KEYS,
  UTILITIES_EXPENSE,
} from '../../../../../../shared/constants/expenses'

import {
  generateInsuranceWriteup,
  generateFuelWriteup,
  generateRepairsAndMaintenanceWriteup,
  generatePayrollAndBenefitsWriteup,
  generateGeneralAndAdministrativeWriteup,
  generateBasicForecastWriteup,
  generateManagementWriteup,
  generateReservesWriteup,
  generateElectricityFuelWriteup,
} from './getExpenseForecastWriteup'

export const DEFAULT_TOTAL_BASIS_OPTIONS = [
  { value: BasisTypes.PER_SF, label: 'Per SF' },
  { value: BasisTypes.PER_UNIT, label: 'Per Unit' },
]
export const DEFAULT_BASIS_OPTIONS = [...DEFAULT_TOTAL_BASIS_OPTIONS, { value: BasisTypes.PER_YEAR, label: 'Per Year' }]

export const ExpenseForecastWriteupGenerators = {
  [DEFAULT_EXPENSES_KEYS.insurance]: generateInsuranceWriteup,
  [DEFAULT_EXPENSES_KEYS.electricity]: generateBasicForecastWriteup,
  [DEFAULT_EXPENSES_KEYS.fuel]: generateFuelWriteup,
  [DEFAULT_EXPENSES_KEYS.waterAndSewer]: generateBasicForecastWriteup,
  [UTILITIES_EXPENSE.key]: generateBasicForecastWriteup,
  [DEFAULT_EXPENSES_KEYS.repairsAndMaintenance]: generateRepairsAndMaintenanceWriteup,
  [DEFAULT_EXPENSES_KEYS.payrollAndBenefits]: generatePayrollAndBenefitsWriteup,
  [DEFAULT_EXPENSES_KEYS.generalAndAdministrative]: generateGeneralAndAdministrativeWriteup,
  [DEFAULT_EXPENSES_KEYS.legalAndProfessionalFees]: generateBasicForecastWriteup,
  [DEFAULT_EXPENSES_KEYS.miscellaneous]: generateBasicForecastWriteup,
  [DEFAULT_EXPENSES_KEYS.management]: generateManagementWriteup,
  [DEFAULT_EXPENSES_KEYS.reserves]: generateReservesWriteup,
  [ADDITIONAL_EXPENSES_KEYS.electricityFuel]: generateElectricityFuelWriteup,
}

export const emptyForecastBasis = {
  sf: {
    compAvg: null,
    ownersProjection: 0,
    recentHistoricalExpenses: [],
  },
  unit: {
    compAvg: null,
    ownersProjection: 0,
    recentHistoricalExpenses: [],
  },
  room: {
    compAvg: null,
    ownersProjection: 0,
    recentHistoricalExpenses: [],
  },
}
