import { GeneratedText, gtParagraph } from '../../../../narrative/GeneratedText'

const generate = () => {
  const paragraphOne =
    'Ownership did not provide proof of compliance with New York City\'s Local Law 97. We note that prudent ownership would adhere to the regulations set by New York City rather than face annual fines although the most recent updates to Local Law 97 include the introduction of new rules that offer flexibility for compliance, such as the "good faith" effort provision, which allows buildings to avoid fines by showing committed efforts toward decarbonization. Additionally, a new electrification credit was introduced, incentivizing buildings to electrify their systems earlier than required. Building owners can achieve compliance through various methods including energy efficiency retrofits, renewable energy installations, and purchasing carbon offsets. In addition, New York City provides expert guidance to connect ownership with financial incentives and financing options to help pay for building upgrades.'
  const paragraphTwo =
    'To the best of our knowledge, the subject is required to comply with Local Law 97. According to the NYC Accelerator, the subject’s projected LL 97 annual penalty for 2024-2029 is expected to be $30,700 per year. Their forecasted penalty between 2030 and 2034 is $257,700 per year. We are unaware of which measures that property management is currently undertaking to be compliant, and assume that the reported accelerator costs, which are included in the addenda of this report, are accurate. We also assume that the subject will be subject to Annual 320 reporting.'

  const paragraphThree =
    'Therefore, it is an extraordinary assumption that ownership will use the provided resources and make all good faith efforts to comply with the law. We have not made any deductions to our value to reflect compliance cost with Local Law 97, but we have considered the risk in the determination of our cap rate.'
  const gt = new GeneratedText()

  gt.addPart(gtParagraph(paragraphOne))
  gt.addPart(gtParagraph(paragraphTwo))
  gt.addPart(gtParagraph(paragraphThree))
  gt.addPart(gtParagraph('See Addenda for additional details regarding Local Law 97.'))

  return gt.getParts()
}

export default { generate }
