import { GeneratedText, gtParagraph, gtText } from '../../../../narrative/GeneratedText'

const generate = () => {
  const localLawIntro =
    'Local Law 97 was included in the Climate Mobilization Act, passed by the City Council in April 2019 as part of the Mayor’s New York City Green New Deal. It is one of the most ambitious plans for reducing emissions in the nation. Local Law 97, which became effective on November 15, 2019, sets limits on the greenhouse gas emissions of covered buildings starting in 2024 to help New York City reach the goal of a 40 percent reduction in greenhouse gas emissions from buildings by the year 2030 and 80 percent reduction in citywide emissions by calendar year 2050.'

  const bulletIntro = `NYC Local Law 97 applies to commercial buildings that are:\n`
  const firstBullet = `\t\u2022 Buildings that exceed 25,000 gross square feet.\n`
  const secondBullet = `\t\u2022 Two or more buildings on the same tax lot that together exceed 50,000 square feet.\n`
  const thirdBullet = `\t\u2022 Two or more buildings owned by a condo association that are governed by the same 
  board of managers and that together exceed 50,000 square feet.`
  const gt = new GeneratedText()
  const bulletGt = new GeneratedText()
  const numberOneBullets = new GeneratedText()
  const numberTwoBullets = new GeneratedText()

  bulletGt.addPart(gtParagraph(bulletIntro))
  bulletGt.addPart(gtText(firstBullet))
  bulletGt.addPart(gtText(secondBullet))
  bulletGt.addPart(gtText(thirdBullet))

  numberOneBullets.addPart(
    gtParagraph(
      '1. Article 320 establishes Building Energy and Emissions Limits for buildings starting in 2024 and outlines the implementation of such limits. Exceptions to Covered Buildings Under Article 320:\n'
    )
  )
  numberOneBullets.addPart(
    gtText('\t\u2022 An industrial facility primarily used for the generation of electric power or steam.\n')
  )
  numberOneBullets.addPart(
    gtText(
      '\t\u2022 Real property, not more than three stories, consisting of a series of attached, detached or semi-detached dwellings, for which ownership and the responsibility for maintenance of the HVAC systems and hot water heating systems is held by each individual dwelling unit owner, and with no HVAC system or hot water heating system in the series serving more than 25,000 gross square feet, as certified by a registered design professional to the department.\n'
    )
  )
  numberOneBullets.addPart(gtText('\t\u2022 A city building.\n'))
  numberOneBullets.addPart(
    gtText('\t\u2022 A housing development or building on land owned by the New York city housing authority.\n')
  )
  numberOneBullets.addPart(
    gtText(
      '\t\u2022 A rent regulated accommodation. The term "rent regulated accommodation" means a building in which more than 35 percent of dwelling units are required by law or by an agreement with a governmental entity to be regulated in accordance with the New York state emergency tenant protection act of 1974, the New York city rent stabilization law of 1969, or the local emergency housing rent control act of 1962.\n'
    )
  )
  numberOneBullets.addPart(
    gtText(
      '\t\u2022 A building whose main use or dominant occupancy is classified as occupancy group A-3 religious house of worship.\n'
    )
  )
  numberOneBullets.addPart(
    gtText(
      '\t\u2022 Real property owned by a housing development fund company organized pursuant to the business corporation law and article eleven of the private housing finance law.\n'
    )
  )
  numberOneBullets.addPart(gtText('\t\u2022 A building that participates in a project-based federal housing program.'))

  numberTwoBullets.addPart(
    gtParagraph(
      '2. Article 321 establishes Energy Conservation Requirements for Certain Buildings that are not covered under Article 320. Article 321 requires that Covered Buildings meet one of the following conditions by 2024 to meet compliance\n'
    )
  )
  numberTwoBullets.addPart(
    gtText(
      '\t\u2022 Report certified by a registered design professional due by May 1, 2025, certifying the annual building emissions did not exceed the carbon limits for 2030-2034.\n'
    )
  )
  numberTwoBullets.addPart(
    gtText(
      '\t\u2022 Report certified by a retro-commissioning agent due by May 1, 2025, certifying the building implemented the applicable Prescriptive Energy Conservation Measures (ECMs) by December 31, 2024. Prescriptive ECMs includes:\n'
    )
  )
  numberTwoBullets.addPart(gtText('\t\t\u2022 Adjust temperature set points for heat and hot water\n'))
  numberTwoBullets.addPart(gtText('\t\t\u2022 Repair heating system leaks\n'))
  numberTwoBullets.addPart(gtText('\t\t\u2022 Maintain heating system\n'))
  numberTwoBullets.addPart(
    gtText('\t\t\u2022 Install individual temperature controls or insulated radiator enclosures\n')
  )
  numberTwoBullets.addPart(gtText('\t\t\u2022 Insulate heating and hot water pipes\n'))
  numberTwoBullets.addPart(gtText('\t\t\u2022 Insulate steam system condensate tank or water tank\n'))
  numberTwoBullets.addPart(gtText('\t\t\u2022 Install heating system sensors and boiler controls\n'))

  gt.addPart(gtParagraph(localLawIntro))
  gt.addParts(bulletGt.getParts())
  gt.addPart(
    gtParagraph(
      'These buildings must keep their carbon emissions under a set limit each year to avoid carbon fines and are referred to as “covered buildings” in the legislation.'
    )
  )
  gt.addPart(
    gtParagraph(
      'Buildings that include affordable and rent-regulated housing are NOT exempt from the requirements of Local Law 97 but may be treated differently under the two articles that make up the law as outlined in Title 28 of the NYC Administrative Code:'
    )
  )
  gt.addParts(numberOneBullets.getParts())
  gt.addParts(numberTwoBullets.getParts())

  return gt.getParts()
}

export default { generate }
