import { findLastIndex, get, inRange, lowerCase } from 'lodash'

import { GEOGRAPHY_OPTIONS } from 'shared/constants/property/geography'
import { getCityStateZip } from 'shared/helpers/property'

import { getDisplayAddress as sharedGetDisplayAddress } from 'shared/utils/propertyHelpers'
import { getLocationIdentifier } from 'shared/utils/report/propertyInformation'

import { GOOGLE_ADDRESS_BOROUGH } from '../../../../shared/constants/property/geography'

import { BOROUGH_NUMBER } from '../../shared/constants/borough'
import { allDistrictInfo, OCEAN_COUNTY } from '../../shared/constants/njDistricts'

import { capitalCase } from '../../../../shared/utils/formatters/textFormatters'
import { STATE_NAMES_SHORT } from '../../../../shared/constants/states'

import { ImageSizes } from '../constants/images'
import { LOCATION_TYPE_OPTIONS, PropertySubtypes } from '../constants/properties'

import { PropertyTypes } from '../../../../shared/constants'
import { getStrategy } from '../components/PropertyWizard/strategy'

export const getPhotoUrl = (address, borough, zip) => {
  const ppi = ImageSizes.PPI
  const landscapeWidth = ImageSizes.LANDSCAPE_WIDTH
  const landscapeHeight = ImageSizes.LANDSCAPE_HEIGHT

  const landscapeW = Math.floor(ppi * landscapeWidth)
  const landscapeH = Math.floor(ppi * landscapeHeight)

  const url = `https://maps.googleapis.com/maps/api/streetview?size=${landscapeW}x${landscapeH}&location=${address} ${borough} ${zip}`

  return encodeURI(url)
}

export const getPropertyType = property => {
  if (property.ComArea === 0 && property.UnitsRes === property.UnitsTotal) {
    return PropertyTypes.MULTI_FAMILY
  }

  if (property.ResArea === 0 && property.ComArea > 0) {
    return PropertyTypes.COMMERCIAL
  }

  const grossBuildingArea = property.ResArea + property.ComArea
  if (property.ComArea > 0 && grossBuildingArea === property.BldgArea) {
    return PropertyTypes.MIXED_USE
  }

  return null
}

export const getPropertySubtype = (floors, isGardenStyle = false) => {
  if (isGardenStyle) {
    return PropertySubtypes.GARDEN_STYLE
  }
  if (!floors) {
    return ''
  }
  if (inRange(floors, 1, 4)) {
    return PropertySubtypes.LOW_RISE
  }
  if (inRange(floors, 4, 7)) {
    return PropertySubtypes.MID_RISE
  }
  return PropertySubtypes.HIGH_RISE
}

export const findProperty = (selected, property) => {
  const propertyIndex = selected.findIndex(prop => get(prop, '_id') === property._id)

  return {
    index: propertyIndex,
    item: propertyIndex === -1 ? null : selected[propertyIndex],
  }
}

const ROAD_TYPES = [
  'st',
  'str',
  'av',
  'ave',
  'pl',
  'blvd',
  'pkwy',
  'rd',
  'cir',
  'ct',
  'dr',
  'hwy',
  'fwy',
  'jct',
  'ln',
  'ter',
  'tpk',
]

const formatStreetAddress = (streetName = '') => {
  const streetParts = streetName.split(' ')
  const streetTypeIndex = findLastIndex(streetParts, part => ROAD_TYPES.includes(lowerCase(part)))
  if (streetTypeIndex !== -1) {
    streetParts[streetTypeIndex] = `${streetParts[streetTypeIndex]}.`
  }
  return streetParts.join(' ')
}

export const getLocationInfoFromAddress = addressInfo => {
  const location = {}

  const addressComponents = get(addressInfo, 'address_components') || []
  for (const part of addressComponents) {
    part.types.forEach(type => {
      location[type] = { short: part.short_name, long: part.long_name }
    })
  }
  let address = get(addressInfo, 'formatted_address')
  const state = get(location, 'administrative_area_level_1.short')
  const county = get(location, 'administrative_area_level_2.short')
  let borough = {}
  const streetNumber = get(location, 'street_number.short')
  let city =
    get(location, 'administrative_area_level_3') ||
    location.locality ||
    location.sublocality ||
    get(location, 'neighborhood')
  const route = get(location, 'route', {})
  const streetName = formatStreetAddress(route.short)
  const country = get(location.country, 'short', '')
  const isNYCAddress = !!GOOGLE_ADDRESS_BOROUGH[city.short] || !!GOOGLE_ADDRESS_BOROUGH[city.long]

  let locationIdentifier = GEOGRAPHY_OPTIONS[state] || GEOGRAPHY_OPTIONS.OTHER
  if (state === STATE_NAMES_SHORT.NY && !isNYCAddress) {
    locationIdentifier = GEOGRAPHY_OPTIONS.OTHER
  }

  if (locationIdentifier === GEOGRAPHY_OPTIONS.NJ) {
    city = get(location, 'administrative_area_level_3') || get(location, 'locality')
  } else if (locationIdentifier === GEOGRAPHY_OPTIONS.NY) {
    city = location.sublocality || location.locality || {}
    borough = {
      short: GOOGLE_ADDRESS_BOROUGH[city.short],
      long: GOOGLE_ADDRESS_BOROUGH[city.long],
    }
    // New York does a pluto lookup without the zip code, therefore, we pass address to it that was used to search by
    address = addressInfo.googlePlace
  }

  let shortAddress
  if (streetNumber && streetName) {
    shortAddress = `${streetNumber} ${streetName}`
  } else {
    shortAddress = addressInfo.googlePlace?.split(',')[0] || ''
  }
  return {
    address,
    streetNumber,
    streetName,
    route,
    shortAddress,
    city: city ? city.long : '',
    borough,
    county,
    locationIdentifier,
    zip: location.postal_code ? location.postal_code.short : '',
    state,
    country,
  }
}

export const getAddressDetails = (locationIdentifier, values) => {
  const strategy = getStrategy(locationIdentifier)

  const details = []
  strategy.uniqueIdentifiers.forEach(({ name }) => {
    if (values[name]) {
      details.push(values[name])
    }
  })
  return details
}

// TODO: deprecated - use getDisplayAddress from shared instead and eventually remove this
export const getDisplayAddress = ({ address, city, state, zip }) => {
  return sharedGetDisplayAddress({ address, city, state, zip })
}

export const getLocationType = locationIdentifier =>
  ({
    [GEOGRAPHY_OPTIONS.NY]: LOCATION_TYPE_OPTIONS.BlockLotBorough,
    [GEOGRAPHY_OPTIONS.NJ]: LOCATION_TYPE_OPTIONS.DistrictBlockLot,
    [GEOGRAPHY_OPTIONS.OTHER]: LOCATION_TYPE_OPTIONS.Other,
  }[locationIdentifier])

const requiredIdentifiers = {
  [GEOGRAPHY_OPTIONS.NY]: ['block', 'lot', 'borough'],
  [GEOGRAPHY_OPTIONS.NJ]: ['block', 'lot'],
  [GEOGRAPHY_OPTIONS.OTHER]: ['address', 'propertyIdentifier'],
}

const compareNYProperties = (comp, searchResult) =>
  comp.block === searchResult.block && comp.lot === searchResult.lot && comp.borough === searchResult.borough
const compareNJProperties = (comp, searchResult) =>
  comp.block === searchResult.block && comp.lot === searchResult.lot && comp.district === searchResult.district
const compareOtherGeographyProperties = (comp, searchResult) =>
  comp.address === searchResult.address && comp.propertyIdentifier === searchResult.propertyIdentifier
const comparePropertiesFallback = (comp, searchResult) => comp.address === searchResult.address

const getPropertiesComparatorByGeography = locationIdentifier => {
  switch (locationIdentifier) {
    case GEOGRAPHY_OPTIONS.NY:
      return compareNYProperties
    case GEOGRAPHY_OPTIONS.NJ:
      return compareNJProperties
    case GEOGRAPHY_OPTIONS.OTHER:
      return compareOtherGeographyProperties
    default:
      return () => false
  }
}

const propertyHasIdentifiers = (property, identifiers) => identifiers.every(identifier => !!property[identifier])

export const compareProperties = (comp, searchResult) => {
  const locationIdentifier = getLocationIdentifier(comp.state, comp.zip, comp.block, comp.lot)
  const identifiers = requiredIdentifiers[locationIdentifier]
  const propertiesHaveIdentifiers =
    propertyHasIdentifiers(comp, identifiers) && propertyHasIdentifiers(searchResult, identifiers)

  const comparator = propertiesHaveIdentifiers
    ? getPropertiesComparatorByGeography(locationIdentifier)
    : comparePropertiesFallback
  return comparator(comp, searchResult)
}

export const getPropertyTypeFromProperty = property => {
  const commercialArea = get(property, 'ComArea', 0)
  const residentialArea = get(property, 'ResArea', 0)
  if (commercialArea > 0 && residentialArea > 0) {
    return PropertyTypes.MIXED_USE
  } else if (commercialArea > 0) {
    return PropertyTypes.COMMERCIAL
  } else {
    return PropertyTypes.MULTI_FAMILY
  }
}

export const getPropertyTypeFromUnitCount = property => {
  const comUnits = get(property, 'comUnits', 0)
  const resUnits = get(property, 'resUnits', 0)
  if (comUnits > 0 && resUnits > 0) {
    return PropertyTypes.MIXED_USE
  } else if (comUnits > 0) {
    return PropertyTypes.COMMERCIAL
  } else {
    return PropertyTypes.MULTI_FAMILY
  }
}

export const getStateAuthorityLink = report => {
  const block = get(report, 'reportData.block')
  const lot = get(report, 'reportData.lot')
  let stateAuthorityLink

  const locationIdentifier = get(report, 'reportData.locationIdentifier')

  const isNYCLocation = locationIdentifier === GEOGRAPHY_OPTIONS.NY
  const isNJLocation = locationIdentifier === GEOGRAPHY_OPTIONS.NJ

  if (isNYCLocation) {
    const borough = get(report, 'reportData.borough')
    const boroughNumber = BOROUGH_NUMBER[borough]
    stateAuthorityLink = `http://a836-acris.nyc.gov/bblsearch/bblsearch.asp?block=${block}&lot=${lot}&borough=${boroughNumber}`
  } else if (isNJLocation) {
    const districtName = get(report, 'reportData.city')
    const districtCountyInfo = allDistrictInfo.find(districtInfo => districtInfo.city === districtName)
    if (districtCountyInfo?.county === capitalCase(OCEAN_COUNTY)) {
      stateAuthorityLink = 'http://www.tax.co.ocean.nj.us/TaxBoardTaxListSearch.aspx'
    } else {
      stateAuthorityLink =
        'https://tax1.co.monmouth.nj.us/cgi-bin/prc6.cgi?&ms_user=monm&passwd=&srch_type=2&adv=0&out_type=0&district=0101'
    }
  }

  return stateAuthorityLink
}

export { getCityStateZip }
