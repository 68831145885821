export const LeaseStatusTypes = {
  Occupied: 'Occupied',
  Vacant: 'Vacant',
  OwnerOccupied: 'Owner Occupied',
}

export const OCCUPANCY_RATE_BASIS_OPTIONS = [{ value: 'unit', label: 'Unit' }, { value: 'sf', label: 'SF' }]

export const OCCUPANCY_RATE_BASIS_TYPES = {
  unit: 'unit',
  sf: 'sf',
}
