import { min, max, mean, omit, get, isNumber } from 'lodash'

import { EXPENSE_HISTORY_TYPES } from 'shared/constants/expenses/expenseHistory'

import { divide } from '../../../utils/numberOperations'

import { BasisTypes, perBasisStub } from '../../../constants/report/incomeApproach/expenses'

import { ExpenseComp, ExpenseForecast, ExpenseItem, ProformaHistory } from './types'

export default {
  calculateManagementFees(effectiveGrossIncome: number, percentOfEgi: number): number {
    if (!effectiveGrossIncome || !percentOfEgi) {
      return 0
    }
    return Math.round(effectiveGrossIncome * percentOfEgi)
  },
  calculateManagementFeesPerBasis(
    totalValue: number,
    basis: string,
    basisValues: { numberOfUnits?: number; squareFootage?: number }
  ): number {
    const { numberOfUnits, squareFootage } = basisValues
    let managementFeesPerBasis = 0
    if (basis === BasisTypes.PER_UNIT && numberOfUnits && numberOfUnits > 0) {
      managementFeesPerBasis = this.calculateValuePerBasis(totalValue, BasisTypes.PER_UNIT, {
        numberOfUnits,
      })
    } else if (basis === BasisTypes.PER_SF && squareFootage && squareFootage > 0) {
      managementFeesPerBasis = this.calculateValuePerBasis(totalValue, BasisTypes.PER_SF, { squareFootage })
    } else if (basis === BasisTypes.PER_YEAR) {
      managementFeesPerBasis = this.calculateValuePerBasis(totalValue, BasisTypes.PER_YEAR, {})
    }

    return managementFeesPerBasis
  },
  calculateValuePerBasis(
    totalValue: number,
    basis: string,
    basisValues: { numberOfUnits?: number; numberOfRooms?: number; squareFootage?: number }
  ): number {
    const { numberOfUnits, numberOfRooms, squareFootage } = basisValues
    const basisMap = {
      [BasisTypes.PER_SF]: squareFootage,
      [BasisTypes.PER_UNIT]: numberOfUnits,
      [BasisTypes.PER_ROOM]: numberOfRooms,
      [BasisTypes.PER_YEAR]: 1,
    }
    if (!totalValue || !basisMap[basis]) {
      return 0
    }
    return divide(totalValue, basisMap[basis])
  },
  calculateTotalFromPerBasis(
    value: number,
    basis: string,
    basisValues: { numberOfUnits?: number; numberOfRooms?: number; squareFootage?: number }
  ): number {
    if (!value) {
      return 0
    }
    const { numberOfUnits, numberOfRooms, squareFootage } = basisValues
    const basisMap = {
      [BasisTypes.PER_SF]: squareFootage,
      [BasisTypes.PER_UNIT]: numberOfUnits,
      [BasisTypes.PER_ROOM]: numberOfRooms,
      [BasisTypes.PER_YEAR]: 1,
    }
    const basisValue = basisMap[basis]
    if (!value || !basisValue) return 0

    return Math.round(basisValue ? value * basisValue : 0)
  },

  expenseUnitLookup(expensePath: string, expenseForecast: ExpenseForecast) {
    const expenseBasis = get(expenseForecast, expensePath) && get(expenseForecast, `${expensePath}.basis`)
    if (expenseBasis) {
      switch (expenseBasis) {
        case BasisTypes.PER_SF:
          return { abbr: BasisTypes.PER_SF, per: perBasisStub.sf, heading: 'PSF' }
        case BasisTypes.PER_UNIT:
          return { abbr: BasisTypes.PER_UNIT, per: perBasisStub.unit, heading: 'Per Unit' }
        case BasisTypes.PER_ROOM:
          return { abbr: BasisTypes.PER_ROOM, per: perBasisStub.room, heading: 'Per Room' }
        case BasisTypes.PER_YEAR:
          return { abbr: BasisTypes.PER_YEAR, per: perBasisStub.year, heading: 'Annual' }
        default:
          return { abbr: 'unit', per: 'per unit', heading: 'Per Unit' }
      }
    }

    return { abbr: 'n/a', per: 'n/a', heading: 'Error' }
  },

  getExpenseCategoryArray(category: keyof ExpenseForecast, abbr: string, expenseComps: ExpenseComp[]) {
    return expenseComps
      .filter((comp: ExpenseComp) => {
        const value = get(comp, `expenses.${category}.value`)
        return isNumber(value)
      })
      .map((expenseComp: ExpenseComp) => {
        if (expenseComp.expenses[category]) {
          switch (abbr) {
            case BasisTypes.PER_SF:
              return this.calculateValuePerBasis(get(expenseComp, ['expenses', category, 'value']), abbr, {
                squareFootage: expenseComp.sqft,
              })
            case BasisTypes.PER_UNIT:
              return this.calculateValuePerBasis(get(expenseComp, ['expenses', category, 'value']), abbr, {
                numberOfUnits: expenseComp.res_units,
              })
            case BasisTypes.PER_YEAR:
              return this.calculateValuePerBasis(get(expenseComp, ['expenses', category, 'value']), abbr, {})
            case BasisTypes.PER_ROOM:
              return 'N/A'
            case 'total':
              return get(expenseComp, ['expenses', category, 'value'])
            default:
              return 0
          }
        }
        return 0
      })
  },

  getCompMin(category: keyof ExpenseForecast, per: string, expenseComps: ExpenseComp[]) {
    const expenseArray = this.getExpenseCategoryArray(category, per, expenseComps)

    return min(expenseArray)
  },

  getCompMax(category: keyof ExpenseForecast, per: string, expenseComps: ExpenseComp[]) {
    const expenseArray = this.getExpenseCategoryArray(category, per, expenseComps)

    return max(expenseArray)
  },

  getCompAvg(category: keyof ExpenseForecast, per: string, expenseComps: ExpenseComp[]) {
    const expenseArray = this.getExpenseCategoryArray(category, per, expenseComps)

    return mean(expenseArray)
  },

  getAllReportedYearAndExpense(category: string, proformaHistory: ProformaHistory) {
    const getFormattedExpenses = (expenses: ExpenseItem[]) => {
      const formattedExpenses: any = {}
      expenses.forEach((expense: ExpenseItem) => {
        formattedExpenses[expense.id] = omit(expense, 'id')
      })

      return formattedExpenses
    }

    if (proformaHistory.units.length) {
      const yearsWithAReportedExpenseForCategory = proformaHistory.units.filter(({ expenses }) => {
        const formattedExpenses = getFormattedExpenses(expenses)
        const value = get(formattedExpenses, [category, 'value'])

        return isNumber(value)
      })
      const yearsAndValuesByExpenseCategory: { expensePeriod: string; expenseYear: number; value: string }[] = []
      if (yearsWithAReportedExpenseForCategory.length) {
        yearsWithAReportedExpenseForCategory.forEach(({ period, year, expenses }) => {
          const formattedExpenses = getFormattedExpenses(expenses)
          const expensePeriod = EXPENSE_HISTORY_TYPES[period]
          const expenseYear = year
          const value = formattedExpenses[category].value
          if (value) {
            yearsAndValuesByExpenseCategory.push({ expensePeriod, expenseYear, value })
          }
        })
      }

      return yearsAndValuesByExpenseCategory.sort((periodA, periodB) => periodA.expenseYear - periodB.expenseYear)
    }

    return []
  },
}
