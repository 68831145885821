import { invert } from 'lodash'

const AVERAGE_CONDITION_TYPE = 'Average'
export const DEFAULT_CONDITION_TYPE = AVERAGE_CONDITION_TYPE
export const RESIDENTIAL_CONDITION_TYPES = [
  'Poor',
  'Down',
  'Fair',
  'Satisfactory',
  AVERAGE_CONDITION_TYPE,
  'Good',
  'Excellent',
]
export const COMMERCIAL_CONDITION_TYPES = [
  'Poor',
  'Down',
  'Fair',
  'Satisfactory',
  AVERAGE_CONDITION_TYPE,
  'Good',
  'Excellent',
]

export const DEFAULT_READY_FOR_OCCUPANCY = 'N/A'

export const DEFAULT_VACANT_READY_FOR_OCCUPANCY = 'Ready for occupancy'
export const READY_FOR_OCCUPANCY_TYPES = [
  DEFAULT_READY_FOR_OCCUPANCY,
  'Not ready for occupancy',
  DEFAULT_VACANT_READY_FOR_OCCUPANCY,
]

export const LEASE_STATUSES = {
  VACANT: 'Vacant',
  OCCUPIED: 'Occupied',
}

export const COMMERCIAL_UNIT_TYPE = 'Commercial'

export const FLOOD_MAP_LOCATIONS = {
  ADDENDA: 'addenda',
  SITE_DESCRIPTION: 'siteDescription',
}

export const FLOOD_MAP_LOCATION_LABELS = {
  [FLOOD_MAP_LOCATIONS.ADDENDA]: 'Addenda',
  [FLOOD_MAP_LOCATIONS.SITE_DESCRIPTION]: 'Site Description',
}

export const OTHER_FACADE = 'other'
export const UNIT_FACADES = ['plate glass']

export const RESIDENTAL_UNIT_RENT_COMP_SOURCE_TYPES = ['pastAppraisal', 'streeteasy']

export const EXTERNAL_WALL_OPTIONS = {
  brick: 'Brick',
  metal: 'Metal',
  glass: 'Glass',
  masonry: 'Masonry',
  concrete: 'Concrete',
  woodPaneling: 'Wood/Composite paneling',
  limestone: 'Limestone',
  vinylSiding: 'Vinyl Siding',
  stone: 'Stone',
  concreteTiltUp: 'Concrete Tilt-up',
  stucco: 'Stucco',
  manufacturedStoneBrick: 'Manufactured Stone/Brick',
  other: 'Other(s)',
}

export const EXTERNAL_WALL_KEYS = invert(EXTERNAL_WALL_OPTIONS)

export const FOUNDATION_OPTIONS = [
  'Poured concrete',
  'Fieldstone',
  'Concrete Block',
  'Masonry',
  'Slab on Grade',
  'Other',
]

export const SURROUNDING_USES = {
  residential: 'Residential',
  commercial: 'Commercial',
  industrial: 'Industrial',
}

export const SURROUNDING_USES_KEYS = invert(SURROUNDING_USES)

export const RECENT_RENOVATIONS = 'Recent Renovations'
export const RECENT_GUT_RENOVATION = 'Recent Gut Renovation'
export const DO_NOT_DISCUSS = 'Do Not Discuss'

export const UNIT_LAYOUTS = [
  'Typical',
  'Duplex',
  'Triplex',
  'Simplex',
  'Penthouse',
  'Loft',
  'Garden Style',
  'Basement',
  'Garage',
  'Townhome',
]
