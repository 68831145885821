import { GeneratedText, gtParagraph } from '../../../../narrative/GeneratedText'

const generate = () => {
  const extraordinaryAssumption =
    "Ownership did not provide proof of compliance with New York City's Local Law 97. We note that prudent ownership would adhere to the regulations set by New York City rather than face annual fines. Building owners can achieve compliance through various methods including energy efficiency retrofits, renewable energy installations, and purchasing carbon offsets. In addition, New York City provides expert guidance to connect ownership with financial incentives and financing options to help pay for building upgrades. Therefore, it is an extraordinary assumption that ownership will use the provided resources and make all good faith efforts to comply with the law. We have not made any deductions to our value to reflect compliance cost with Local Law 97, but we have considered the risk in the determination of our cap rate."

  const gt = new GeneratedText()

  gt.addPart(gtParagraph(extraordinaryAssumption))

  return gt.getParts()
}

export default { generate }
