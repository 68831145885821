import { find, get, isNil, keyBy, meanBy } from 'lodash'

import { filterUnitsByRentType, getAnnualIncreaseForRentType } from './helpers'
import { RentType, RentTypeAnnualIncrease, RentTypeSummary, ResidentialUnit } from './types'

export default {
  calculateTotalAnnualResidentialIncome(
    units: ResidentialUnit[],
    rentTypes: RentType[],
    rentTypeAnnualIncreases: RentTypeAnnualIncrease[]
  ) {
    let totalPotentialResidentialIncome = 0
    if (rentTypes.length) {
      rentTypes.forEach(rentType => {
        const annualRentByRentType = this.calculateTotalAnnualRentByRentType(rentType, units)
        const rentTypeAnnualIncrease = getAnnualIncreaseForRentType(rentType, rentTypeAnnualIncreases)
        totalPotentialResidentialIncome += annualRentByRentType * rentTypeAnnualIncrease
      })
    }
    return totalPotentialResidentialIncome
  },
  // This still depends on the shape of "units" - think through a better way to do this, maybe send in "rents"??
  calculateTotalAnnualRentByRentType(rentType: RentType, units: ResidentialUnit[]) {
    const unitsByRentType = filterUnitsByRentType(rentType, units)
    return this.calculateTotalAnnualRent(unitsByRentType)
  },
  calculateTotalAnnualRent(units: ResidentialUnit[]) {
    const annualRent =
      units.reduce((sum, unit) => {
        const monthlyUnitRent = unit.rent || 0
        return sum + monthlyUnitRent
      }, 0) * 12
    return annualRent
  },
  calculateTotalAverageRent(units: ResidentialUnit[]) {
    return meanBy(units, 'rent')
  },
  calculateTotalResidentialVCLoss(totalIncome: number, vcLossPercentage: number) {
    if (!totalIncome || !vcLossPercentage) {
      return 0
    }
    return totalIncome * vcLossPercentage
  },
  calculateTotalPotentialResidentialIncomeFromSummary(rentTypeSummary: RentTypeSummary[]) {
    return rentTypeSummary.reduce((sum, summaryItem) => {
      const rentWithIncrease = summaryItem.annualRent * summaryItem.increase || 0
      return sum + rentWithIncrease
    }, 0)
  },
  calculatePotentialResidentialIncomeByType(rentTypeSummary: RentTypeSummary) {
    const { annualRent, increase } = rentTypeSummary
    if (!annualRent || !increase) {
      return 0
    }
    return annualRent * increase
  },
  calculateTotalRoomCount(residentialUnits: ResidentialUnit[]) {
    if (!residentialUnits || !residentialUnits.length) {
      return 0
    }
    // @ts-ignore typescript doesn't like reducing
    const totalRoomCount = residentialUnits.reduce((sum: number, unit: ResidentialUnit) => {
      const numberOfRoomsInUnit = isNil(unit.rooms) ? parseInt(unit.bedrooms, 10) + 2 : parseInt(unit.rooms, 10)

      return sum + numberOfRoomsInUnit
    }, 0)

    return totalRoomCount
  },
  getMonthlyIncome(residential: any, increaseValue = true) {
    let income = 0
    const units = get(residential, 'projectedRentRoll') || []

    units.forEach((unit: ResidentialUnit) => {
      const factor: number = increaseValue ? this.getAnnualIncreaseForRentType(residential, unit.rentType) : 1
      income += factor * unit.rent
    })

    return income
  },
  getProjectedAnnualIncome(residential: any, increaseValue = true) {
    return this.getMonthlyIncome(residential, increaseValue) * 12
  },
  getProjectedMarketPercentage(residential: any) {
    if (!residential) {
      return 0
    }
    const { unitGroups = [], units = [] } = residential
    const unitsById = keyBy(units, '_id')

    const totalPotentialRent = unitGroups.reduce((total: any, unitGroup: any) => {
      const rentConclusion = unitGroup.rentConclusion
      if (!rentConclusion) {
        return total
      }
      const concludedRent = rentConclusion.market_conclusion || 0
      if (rentConclusion.market_conclusion_unit === 'SF' && residential.exportOptions.perUnitSF) {
        const totalSquareFeet = unitGroup.units.reduce((sum: any, unitId: any) => {
          const unit = unitsById[unitId]
          return sum + parseFloat(unit.sqft)
        }, 0)
        return total + totalSquareFeet * concludedRent
      }

      return total + unitGroup.units.length * concludedRent * 12
    }, 0)

    if (totalPotentialRent > 0) {
      return this.getProjectedAnnualIncome(residential, false) / totalPotentialRent
    }
    return 0
  },
  getAnnualIncreaseForRentType(residential: any, rentType: string) {
    let increase = 1
    const rentTypeIncrease = find(
      residential.rentTypeAnnualIncreases,
      increaseItem => increaseItem.rentType === rentType
    )
    if (rentTypeIncrease) {
      increase = rentTypeIncrease.increase
    }
    return increase
  },
  getVacantUnits(residential: any) {
    const vacantUnits = residential.units.filter((unit: ResidentialUnit) => unit.isVacant)
    return vacantUnits.sort((prev: any, next: any) => prev.bedrooms > next.bedrooms)
  },
}
