import { handleActions } from 'redux-actions'

import { DEFAULT_SORT_TYPE } from '../constants'

import { clearImportRentComps, importRentCompsFail, importRentCompsReceive, importRentCompsRequest } from './actions'

const initialState = {
  rentComps: [],
  rentCompsByCoords: [],
  polygon: [],
  isCustomSelection: false,
  filters: {
    polygon: [],
    sortType: DEFAULT_SORT_TYPE,
  },
  importedRentCompsData: { comps: [], outdatedComps: [] },
  areReportRentCompsLoading: false,
}

export const reducer = handleActions(
  {
    [importRentCompsRequest](state) {
      return {
        ...state,
        areReportRentCompsLoading: true,
      }
    },
    [importRentCompsReceive](state, { payload }) {
      return {
        ...state,
        areReportRentCompsLoading: false,
        importedRentCompsData: payload.rentCompsData,
      }
    },
    [importRentCompsFail](state) {
      return {
        ...state,
        areReportRentCompsLoading: false,
      }
    },
    [clearImportRentComps](state) {
      return { ...state, importedRentCompsData: { comps: [], outdatedComps: [] }, areReportRentCompsLoading: false }
    },
  },
  initialState
)
