import { createAction } from 'redux-actions'
import { defineAction } from 'redux-define'

import { DEFAULT_API_ACTION_STATES } from '../../../../../../shared/constants'

/*
 * Action types
 */
const IMPORT_RENT_COMPS = defineAction('IMPORT_RENT_COMPS', DEFAULT_API_ACTION_STATES)
const CLEAR_IMPORT_RENT_COMP = defineAction('CLEAR_IMPORT_RENT_COMP')
/*
 * Actions
 */

export const importRentComps = createAction(IMPORT_RENT_COMPS.ACTION)
export const importRentCompsRequest = createAction(IMPORT_RENT_COMPS.REQUEST)
export const importRentCompsReceive = createAction(IMPORT_RENT_COMPS.RECEIVE)
export const importRentCompsFail = createAction(IMPORT_RENT_COMPS.FAIL)
export const clearImportRentComps = createAction(CLEAR_IMPORT_RENT_COMP.ACTION)
