import { GeneratedText, gtLocked, gtConditional, gtTemplate } from '../../../narrative/GeneratedText'

import { mapDTO, mapDataModel } from './mappings'

const NY_SOURCES_SENTENCE =
  'The data contained within this appraisal was compiled from market analysis utilizing the following sources (unless otherwise noted): NYC Department of Finance, NYC Department of Buildings, NYC Department of Planning Zoning & Land Use, Claritas, CoStar, Federal Reserve, and FEMA.'
const OTHER_SOURCES_SENTENCE =
  'The data contained within this appraisal was compiled from market analysis utilizing the following sources (unless otherwise noted): the City Tax Assessor, state and county tax records, the Zoning Board, Claritas, CoStar, Attom, Federal Reserve, and FEMA.'

const generate = () => {
  const gt = new GeneratedText()

  const nyGeographyLocationIdentifierConditional = gtConditional('isNYLocationIdentifier')

  nyGeographyLocationIdentifierConditional.children.push(...gtTemplate`${NY_SOURCES_SENTENCE}`)
  gt.addPart(nyGeographyLocationIdentifierConditional)

  const otherGeographyLocationIdentifierConditional = gtConditional('isOtherGeographyLocationIdentifier')

  otherGeographyLocationIdentifierConditional.children.push(...gtTemplate`${OTHER_SOURCES_SENTENCE}`)
  gt.addPart(otherGeographyLocationIdentifierConditional)

  gt.t` The subject photos were taken by ${gtLocked('reportInspectors', null, 'Report Inspectors')}`
  gt.t` on ${gtLocked('inspectionDate', null, 'Inspection Date')}, while those used for the comparable`
  gt.t`${gtConditional('incomeApproachSelected', gtTemplate` rentals and`)} sales were sourced from the public domain.`
  gt.t` When possible, we have confirmed the reported data with parties to the transactions or`
  gt.t` those who are intimately familiar with their critical details.`

  return gt.getParts()
}

export default { generate, mapDTO, mapDataModel }
