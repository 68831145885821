import { get, isNil, toLower, lowerCase } from 'lodash'

import { STATE_NAMES, STATE_NAMES_SHORT } from 'shared/constants/states'
import {
  GEOGRAPHY_OPTIONS,
  GOOGLE_ADDRESS_BOROUGH,
  NYC_ZIP_CODES,
  QUEENS_NEIGHBORHOODS,
} from 'shared/constants/property/geography'

import { CONDITIONS_OPTIONS } from '../../../../libs/bowery-libs/constants/report'
import { OTHER_FACADE, UNIT_FACADES } from '../../../constants/report/property'

export const isNYC = (state, zipCode) =>
  (state === STATE_NAMES.NY || state === STATE_NAMES_SHORT.NY) && NYC_ZIP_CODES.includes(zipCode)

export const getNYCBorough = city => {
  if (QUEENS_NEIGHBORHOODS.includes(city)) {
    return GOOGLE_ADDRESS_BOROUGH.Queens
  }
  return GOOGLE_ADDRESS_BOROUGH[city]
}

export const getLocationIdentifier = (state, zipCode, block, lot) => {
  if (!block || !lot) {
    return GEOGRAPHY_OPTIONS.OTHER
  }
  if (state === STATE_NAMES.NJ || state === STATE_NAMES_SHORT.NJ) {
    return GEOGRAPHY_OPTIONS.NJ
  }
  if (isNYC(state, zipCode)) {
    return GEOGRAPHY_OPTIONS.NY
  }
  return GEOGRAPHY_OPTIONS.OTHER
}

export const mapUnitFacade = (unit = {}, path = 'description.facade') => {
  let facade = get(unit, path)
  let otherFacade = null
  const isOtherFacade = !isNil(facade) && !UNIT_FACADES.includes(lowerCase(facade))
  if (isOtherFacade) {
    otherFacade = facade
    facade = OTHER_FACADE
  }
  const mappedFacade = {
    facade,
    otherFacade,
  }
  return mappedFacade
}

export const mapUnitFacadeUpdate = (unit = {}) => {
  const { otherFacade, facade } = unit
  const isOtherFacade = facade === OTHER_FACADE
  if (isOtherFacade) {
    return otherFacade
  }
  if (isNil(facade) || facade === '') {
    return null
  }
  return toLower(facade)
}

export const getConditionValue = (object, accessor, defaultValue = 1) => {
  const value = get(object, accessor, defaultValue)
  return CONDITIONS_OPTIONS[value]
}
