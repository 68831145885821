import React from 'react'

import { Paper, Grid } from '@mui/material'

import localLawIntro from 'shared/utils/textGeneration/cms/localLaw97/localLawIntro'
import noPenalty from 'shared/utils/textGeneration/cms/localLaw97/noPenalty'
import penaltyOnAccelerator from 'shared/utils/textGeneration/cms/localLaw97/penaltyOnAccelerator'
import extraordinaryAssumption from 'shared/utils/textGeneration/cms/localLaw97/extraordinaryAssumption'
import nycClimateMobilizationAct from 'shared/utils/textGeneration/cms/localLaw97/nycClimateMobilizationAct'
import violationsForNonCompliance from 'shared/utils/textGeneration/cms/localLaw97/violationsForNonCompliance'

import NarrativeComponent from '../../shared/components/NarrativeComponent'

import withCMSForm from './withCMSForm'

const GRID_CONTAINER_GUTTER = 2

const LocalLaw97 = () => {
  return (
    <Paper>
      <Grid container spacing={GRID_CONTAINER_GUTTER}>
        <Grid item xs={12}>
          <NarrativeComponent
            generatedText={localLawIntro.generate}
            name="localLaw97.localLawIntro"
            title="Local Law 97 Introduction"
          />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent generatedText={noPenalty.generate} name="localLaw97.noPenalty" title="No Penalty" />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent
            generatedText={penaltyOnAccelerator.generate}
            name="localLaw97.penaltyOnAccelerator"
            title="Penalty on Accelerator"
          />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent
            generatedText={extraordinaryAssumption.generate}
            name="localLaw97.extraordinaryAssumption"
            title="Extraordinary Assumption"
          />
        </Grid>
        <Grid item xs={12}>
          <NarrativeComponent
            generatedText={nycClimateMobilizationAct.generate}
            name="localLaw97.nycClimateMobilizationAct"
            title="NYC Climate Mobilization Act (Addenda)"
          />
        </Grid>

        <Grid item xs={12}>
          <NarrativeComponent
            generatedText={violationsForNonCompliance.generate}
            name="localLaw97.violationsForNonCompliance"
            title="Violations for Non-Compliance"
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

export default withCMSForm(LocalLaw97, 'Local Law 97')
