import React, { FC, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import { get } from 'lodash'

import { DEFAULT_ALERT_MESSAGES } from 'shared/constants/automation/messages'
import AutomationCTA from 'client-shared/components/AutomationCTA'
import { useSelector } from 'client-shared/hooks/redux'
import usePrevious from 'client-shared/hooks/usePrevious'

import * as Api from '../../../../api'
import { CompPlexInputEvents } from '../../../../../shared/constants/compPlex'
import { transformSalesCompToRawOutput } from '../CreateSalesComp/drm/helpers/transformers'

type SalesCompAutomationProps = {
  compPlexRef: React.RefObject<HTMLDivElement>
  saveReport: (formPath: string) => void
  formPath: string
  subjectProperty: any
  updateSelectedSalesComps: (comps: any[]) => Promise<any[]>
  isLandSearch: boolean
}

const SalesCompsAutomation: FC<SalesCompAutomationProps> = ({
  compPlexRef,
  saveReport,
  formPath,
  subjectProperty,
  updateSelectedSalesComps,
  isLandSearch,
}) => {
  const form = useForm()
  const [snapshotRequested, setSnapshotRequested] = useState(false)
  const reportId = useSelector(state => get(state, 'report.reportData._id'))
  const isSubmitting = useSelector(state => get(state, 'report.submitting'))
  const wasSubmitting = usePrevious(isSubmitting)
  const autoSalesComps = useSelector(state => get(state, 'report.reportData.organizationSettings.autoSalesComps'))

  useEffect(() => {
    if (isSubmitting && !wasSubmitting && snapshotRequested) {
      setSnapshotRequested(false)
      Api.takeSnapshot(reportId, 'auto-sales-comps', ['new.salesApproach.salesCompsSearch.selectedComps'])
    }
  }, [isSubmitting, reportId, snapshotRequested, wasSubmitting])

  const getSubjectPropertyInfo = () => {
    const formValues = form.getState().values
    subjectProperty.incomeApproachValue = formValues?.incomeApproachValue
    subjectProperty.subjectPropertyInformation = formValues?.subjectPropertyInformation
    return subjectProperty
  }

  const updateFilters = (searchParams: any) => {
    compPlexRef.current?.dispatchEvent(
      new CustomEvent(CompPlexInputEvents.OVERRIDE_SMART_FILTERS, {
        detail: searchParams,
      })
    )
  }

  const getAutoSalesComps = async () => {
    const subject = getSubjectPropertyInfo()

    if (!subject.incomeApproachValue?.unit || !subject?.concludedCapRate) {
      throw new Error('Please complete the income approach before automatically selecting sales comps.')
    }

    const { comps, searchParams } = await Api.getAutoSalesComps(subject, isLandSearch, autoSalesComps)
    const localComps = comps.map((comp: any) => {
      const { propertyIdentifier } = comp.address
      const propertyIdentification = {
        propertyIdentifier: propertyIdentifier?.value,
        propertyIdentifierType: propertyIdentifier?.type,
      }
      comp.address.propertyIdentification = propertyIdentification
      const localComp = transformSalesCompToRawOutput(comp)
      return {
        ...localComp,
        isAutoComp: true,
        score: comp.score,
      }
    })
    await updateSelectedSalesComps(localComps)
    updateFilters(searchParams)

    setSnapshotRequested(true)
    global.automationSaved = 'auto-sales-comps'
    saveReport(formPath)
  }

  const getAutoSalesCompsErrorMsg = () => {
    const subject = getSubjectPropertyInfo()
    if (!subject.incomeApproachValue?.unit || !subject.concludedCapRate) {
      return 'Please complete the income approach before automatically selecting sales comps.'
    }
    return DEFAULT_ALERT_MESSAGES.ERROR
  }

  const onAutoSalesCompsError = () => {
    console.error('Error loading auto sales comps')
  }

  useEffect(() => {
    if (form.getState().values?.selectedComps?.length === 0) {
      getAutoSalesComps().catch(() => {
        // noop
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <AutomationCTA
      CTAMessage="Automate your selection of comparable sales through Bowery AI."
      successMessage="Comparable sales have been successfully added to the report. Please verify they are appropriate."
      errorMessage={getAutoSalesCompsErrorMsg()}
      onAutomationRun={getAutoSalesComps}
      onAutomationError={onAutoSalesCompsError}
    />
  )
}

export default SalesCompsAutomation
